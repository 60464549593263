import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.PARENTS]: {
		el: {
			'Address': 'Διεύθυνση',
			'School': 'Σχολείο',
			'Sch.Year': 'Σχ.έτος',
			'Itineraries': 'Δρομολόγια',
			'Guardian Name': 'Επωνυμία Κηδεμόνα',
			'Starting Point': 'Αφετηρία',
			'End Point': 'Τέρμα',
			'Itinerary to school': 'Δρομολόγιο προς το σχολείο',
			'Itinerary from school': 'Δρομολόγιο από το σχολείο',
			'Sponsor': 'Ανάδοχος',
			'Status': 'Κατάσταση',
			'Transfer Type': 'Μέσο Μεταφοράς',
			'Appear to map': 'Εμφάνιση στον χάρτη',
			'toSchool': 'Δρομολόγιο για το σχολείο',
			'fromSchool': 'Δρομολόγιο από το σχολείο',
			'Save Point': 'Αποθήκευση Σημείου',
			'Save Point For All Routes For Child': 'Αποθήκευση Σημείου Για Όλα Τα Δρομολόγια Του Παιδιού',
			'Save Point For All Routes For All Children': 'Αποθήκευση Σημείου Για Όλα Τα Δρομολόγια Όλων Των Παιδιών',
			'Bus stops & Finally': 'Στάσεις & Τέρμα',
			'Stated home address': 'Δηλωμένη διεύθυνση οικίας',
			'Selected home address': 'Επιλεγμένη διεύθυνση οικίας'
		},
		en: {
			'toSchool': 'Route to school',
			'fromSchool': 'Route from school',
		}
	},
}

export default locales