// ==============================|| OVERRIDES - SELECT ||============================== //

export default function Select(theme) {
	return {
		MuiSelect: {
			styleOverrides: {
				root: {},
				select: {}
			}
		}
	}
}
