// ==============================|| OVERRIDES - TABS ||============================== //

export default function Tabs(theme) {
	return {
		MuiTabs: {
			styleOverrides: {
				vertical: {},
				indicator: {},
				root: {}
			}
		}
	}
}
