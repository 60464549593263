// ==============================|| OVERRIDES - TOOLBAR ||============================== //

export default function Toolbar(theme) {
	return {
		MuiToolbar: {
			styleOverrides: {
				root: {},
				selectLabel: {},
				displayedRows: {},
			},
		}
	}
}
