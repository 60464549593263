// ==============================|| OVERRIDES - CHECKBOX ||============================== //

export default function Checkbox(theme) {
	return {
		MuiCheckbox: {
			defaultProps: {},
			styleOverrides: {
				root: {},
			}
		}
	}
}