// assets
// import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

// ==============================|| OVERRIDES - ACCORDION SUMMARY ||============================== //

export default function AccordionSummary(theme) {
	return {
		MuiAccordionSummary: {
			defaultProps: {
				// expandIcon: <ArrowForwardIosOutlinedIcon sx={{ fontSize: '0.75rem' }}/>
			},
			styleOverrides: {
				root: {},
				expandIconWrapper: {},
				content: {}
			}
		}
	}
}
