// @flow
import React from "react";
import {
	Box
} from "@mui/material"

const Details = () => {
	return (
		<Box>
			User Details
		</Box>
	)
}

// export default withPermissionHOC(Details, ['access_user_details']) //TODO::Uncomment when permissions are ready
export default Details //TODO::Delete when permissions are ready