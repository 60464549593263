// ==============================|| OVERRIDES - DATA GRID ||============================== //

export default function DataGrid(theme) {
	return {
		MuiDataGrid: {
			styleOverrides: {
				sortIcon: {},
				editInputCell: {},
				cell: {
					borderBottom: `1px solid ${theme.palette.gridColors.borders}`,
				},
				withBorderColor: {
					borderColor: `${theme.palette.gridColors.borders}`
				},
				columnHeader: {
					backgroundColor: `${theme.palette.gridColors.headerBackground}`,
					color: `${theme.palette.gridColors.headerFont}`,
					svg: {
						color: `${theme.palette.gridColors.headerSvg}`
					}
				},
				root: {
					// fontSize: '0.8rem',
					backgroundColor: theme.palette.background.paper,
					border: `1px solid ${theme.palette.gridColors.borders}`,
					'& .MuiDataGrid-aggregationColumnHeaderLabel': {
						display: 'none'
					},
					'& .MuiDataGrid-cell': {
						// display: 'flex',
						// textOverflow: 'ellipsis',
						// alignItems: 'center'
					},
					'& .MuiDataGrid-columnHeaderTitle': {
						// fontWeight: 600,
					},
					'& .MuiDataGrid-headerFilterRow .MuiDataGrid-columnHeader': {
						// color: 'inherit',
						// backgroundColor: '#F0F8FF'
					},
					'& .MuiTablePagination-toolbar': {
						// paddingTop: 0
					},
					'& .MuiDataGrid-panelFooter button:first-child': {
						// display: 'none'
					},
					'& .MuiDataGrid-cell .MuiButton-root': {
						// padding: 0
					},
				},
				toolbarButton: {
					// color: theme.palette.secondary[700]
				},
			},
		},
	}
}