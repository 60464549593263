// @flow
import React from 'react';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { getRouteUrl } from "../../../../helpers/routing/getRouteUrl";
import { ROUTE_PAGE_CONTRACTOR_ROUTES_INDEX } from "../../../../modules/contractors/routes/routes";
import { ROUTE_PAGE_USERS_INDEX } from "../../../../modules/users/routes/routes";
import {
	ROUTE_PAGE_HOME
} from "../../../../routers/routes";
import { useTranslation } from "react-i18next";
import { NAVIGATION } from "../../../../locales/components/namespaces";
import { menuStyle } from "../header/header";
type Props = {
	navItems: Array<{
		id: number,
		url: string,
		title: string
	}>
};

const MainMenu = (props: Props) => {
	const { t } = useTranslation(NAVIGATION)
	
	return (
		<Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, marginRight: 'auto', flexWrap: 'wrap' }}>
			<Box pr={2}>
				<Button
					component={Link}
					to={getRouteUrl(ROUTE_PAGE_HOME)}
					sx={{
						...menuStyle
					}}
				>
					{t('Home')}
				</Button>
			</Box>
			<Box pr={2}>
				{/*TODO:: User PermissionHoc for user access when permissions are ready!*/}
				{/*<PermissionHOC*/}
				{/*	requiredPermissions={['user permission']}>*/}
				{/*	<Button*/}
				{/*		component={Link}*/}
				{/*		to={getRouteUrl(ROUTE_PAGE_USERS_INDEX)}*/}
				{/*		sx={{ ...menuStyle }}*/}
				{/*	>*/}
				{/*		{t('Users')}*/}
				{/*	</Button>*/}
				{/*</PermissionHOC>*/}
				{/*<Button*/}
				{/*	component={Link}*/}
				{/*	to={getRouteUrl(ROUTE_PAGE_USERS_INDEX)}*/}
				{/*	sx={{ ...menuStyle }}*/}
				{/*>*/}
				{/*	{t('Users')}*/}
				{/*</Button>*/}
				{/*<Button*/}
				{/*	component={Link}*/}
				{/*	to={getRouteUrl(ROUTE_PAGE_CONTRACTOR_ROUTES_INDEX)}*/}
				{/*	sx={{ ...menuStyle }}*/}
				{/*>*/}
				{/*	{t('Contractors')}*/}
				{/*</Button>*/}
			</Box>
			{/*<Box pr={2}>*/}
			{/*	<Button*/}
			{/*		component={Link}*/}
			{/*		to={getRouteUrl(ROUTE_PAGE_A_PARENT_PAGE)}*/}
			{/*		sx={{ ...menuStyle }}*/}
			{/*	>*/}
			{/*		{t('Parent Page')}*/}
			{/*	</Button>*/}
			{/*</Box>*/}
		</Box>
	);
};

export default MainMenu