// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme) {
	return {
		MuiTableCell: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
