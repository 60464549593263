import axios from "axios";
import React, { useEffect, useState } from 'react';
import useUserData from "../../../hooks/user/useUserData";

const useParent = (props: Props) => {
	const { apiITYEToken } = useUserData();
	console.log(apiITYEToken);
	// const { afm } = useUserData()
	
	// const apiITYEToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImVyZ29sb2dpYyIsImlhdCI6MTcyNTUyODk0NCwiZXhwIjoxNzQxMDgwOTQ0fQ.43d8MR7i6yxvJcR4CNTagZtHg1lamF1d8v9vGrM32BA';
	
	const [parentData, setParentData] = useState([]);
	const [loading, setLoading] = useState(false);
	const afm = '123456789';
	
	useEffect(() => {
		if (apiITYEToken) {
			let current = true;
			setLoading(true);
			axios.request({
				headers: {
					Authorization: `Bearer ${apiITYEToken}`
				},
				method: "GET",
				url: `https://api-devtransport-pde.cti.gr/api/openapi/parent?afm=` + afm
			}).then(response => {
				if (current) {
					setParentData(response.data.data);
				}
				setLoading(false);
			}).catch(error => {
				//TODO::enqueue error notification
				setLoading(false);
			})
			
			return () => {
				current = false;
			}
		}
	}, [apiITYEToken,afm])
	
	return {
		parentData,
		afm,
		loading
	};
	
};

export default useParent