// ==============================|| OVERRIDES - FORM HELPER TEXT ||============================== //

export default function FormHelperText(theme) {
	return {
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					// marginLeft: 0,
					// color: theme.palette.error.main,
					'&.Mui-error': {
						// color: theme.palette.error.main
					}
				}
			}
		}
	}
}
