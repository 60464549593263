import { routeCreator } from "../../../helpers/routing/routeCreator";
import Index from '../pages/index'
import Create from "../pages/create/create";
import Details from "../pages/details/details"

export const ROUTE_PAGE_USERS_INDEX = routeCreator(Index, '/users', 'Users', 'page.users', {exact: true})
export const ROUTE_PAGE_USERS_CREATE = routeCreator(Create, '/user/create', 'User Create', 'page.users.create', {exact: true})
export const ROUTE_PAGE_USERS_EDIT = routeCreator(Create, '/user/edit/:id', 'User Edit', 'page.users.edit', {exact: true})
export const ROUTE_PAGE_USERS_DETAILS = routeCreator(Details, '/user/details/:id', 'User Details', 'page.users.details', {exact: true})

const router = {
    ROUTE_PAGE_USERS_INDEX,
    ROUTE_PAGE_USERS_CREATE,
    ROUTE_PAGE_USERS_EDIT,
    ROUTE_PAGE_USERS_DETAILS
}

export default router