// ==============================|| OVERRIDES - FORM LABEL ||============================== //

export default function FormLabel (theme) {
    return {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    // fontSize: '0.8rem',
                    // wordWrap: 'break-word',
                    // color: theme.palette.inputPalette.labelColor,
                    // backgroundColor: theme.palette.inputPalette.labelBackground,
                    // lineHeight: '1.25rem',
                }
            }
        }
    }
}