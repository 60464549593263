// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

export default function IconButton(theme) {
	return {
		MuiIconButton: {
			styleOverrides: {
				root: {
					// borderRadius: 4
				},
				sizeLarge: {},
				sizeMedium: {},
				sizeSmall: {}
			}
		}
	}
}
