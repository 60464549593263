// @flow
import React from 'react';
import Box from "@mui/material/Box";
import useUserData from "../../hooks/user/useUserData";
import Index from "../../modules/contractors/pages/index";
import ParentDashboard from "../../modules/parents/pages/parentDashboard/parentDashboard";

type Props = {};

const Home = (props: Props) => {
	const { username } = useUserData();
	console.log("Username:", username);

	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap'
			}}
			width={'100%'}
		>
			{/*<ParentDashboard/>*/}
			{username === 'Περιφέρεια Δυτικής Ελλάδας' &&
				<ParentDashboard/>
			}
			{username === 'ΠΔΕ' &&
				<Index/>
			}
		</Box>
	);
};

export default Home