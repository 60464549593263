// @flow
import Box from "@mui/material/Box";

const Create = () => {
	return (
		<Box>User Create/Edit</Box>
	)
}

// export default withPermissionHOC(Create, ['access_user_create', 'access_user_edit']) //TODO::Uncomment when permissions are ready
export default Create //TODO::Delete when permissions are ready