// ==============================|| OVERRIDES - BUTTON GROUP ||============================== //

export default function ButtonGroup(theme) {
	return {
		MuiButtonGroup: {
			defaultProps: {
				disableRipple: true
			},
			styleOverrides: {
				root: {},
				grouped: {},
			}
		}
	}
}
