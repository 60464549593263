// @flow
import React from 'react';
import { Box} from "@mui/material";

type Props = {};

const Index = (props: Props) => {
	return (
		<Box>
			Users Index
		</Box>
	);
};

// export default withPermissionHOC(Index, ['access_user_index']) //TODO::Uncomment when permissions are ready
export default Index //TODO::Delete when permissions are ready