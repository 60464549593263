import * as ActionTypes from '../../actions/types'
import { fromJS } from "immutable";
import { mapUser } from "../../types/user/map";
import { mapDBDateFormats, mapDBTimeFormats } from "../../helpers/calculations/date";
import { getRouteUrl } from "../../helpers/routing/getRouteUrl";
import { ROUTE_PAGE_LOGIN } from "../../routers/routes";

const INITIAL_STATE = {
	token: null,
	language: 'el',
	decimalSeparator: ',',
	thousandSeparator: '.',
	dateFormat: 'DD/MM/YYYY',
	timeFormat: 'HH:mm',
	loading: false,
	isLoggedIn: false,
	message: null,
	tokenExpirationDateTime: null,
	passwordReset: false,
	recoveryPwdTime: null,
	resetTime: null,
	data: {},
	changeFormatsWithLang: true,
	successResetPass: false,
	apiITYEToken: null,
	loadingITYE: false,
	username: null,
}

function setUserLanguage(state, action) {
	return state
	.set('language', action.payload)
	.set('decimalSeparator', state.get('changeFormatsWithLang') ? action.payload === 'el' ? ',' : '.' : state.get('decimalSeparator'))
	.set('thousandSeparator', state.get('changeFormatsWithLang') ? action.payload === 'el' ? '.' : ',' : state.get('thousandSeparator'))
	.set('dateFormat', state.get('changeFormatsWithLang') ? action.payload === 'el' ? 'DD/MM/YYYY' : 'MM/DD/YYYY' : state.get('dateFormat'))
	.set('timeFormat', state.get('changeFormatsWithLang') ? action.payload === 'el' ? 'HH:mm' : 'HH:mm' : state.get('timeFormat'))
}

function setUserMessage(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function setResetPassword(state, action) {
	const passwordReset = action.payload
	return state
	.set('passwordReset', passwordReset)
}

function checkAPIConnectivity(state) {
	return state
	.set('loading', true)
	.set('message', null)
	.set('passwordReset', false)
}

function checkAPIConnectivitySucceeded(state) {
	return state
	.set('loading', false)
	.set('message', null)
}

function checkAPIConnectivityFailed(state) {
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: 'Connection to API failed!', variant: 'error'
	}))
}

function userLogoutSucceeded(state, action) {
	window.location.href = getRouteUrl(ROUTE_PAGE_LOGIN)
	return state
	.set('message', null)
	.set('token', null)
	.set('loading', false)
	.set('isLoggedIn', false)
}

function userLogin(state) {
	return state
	.set('message', null)
	.set('token', null)
	.set('loading', true)
	.set('isLoggedIn', false)
	.set('selectedStore', null)
	.set('passwordReset', false)
}

function userLoginSucceeded(state, action) {
	const {
		PortalLangBehavior,
		access_token,
		dateFormat,
		decimalChar,
		expires_in,
		langcode,
		thousandsChar,
		timeFormat,
		user_name
	} = action.payload
	const tokenExpirationDateTime = new Date()
	tokenExpirationDateTime.setSeconds(tokenExpirationDateTime.getSeconds() + expires_in)
	return state
	.set('message', null)
	.set('loading', false)
	.set('token', access_token)
	.set('username', user_name)
	.set('tokenExpirationDateTime', tokenExpirationDateTime)
	.set('isLoggedIn', true)
	.set('changeFormatsWithLang', PortalLangBehavior === '1')
	.set('language', langcode ? langcode !== 'EL' && langcode !== 'EN' ? 'en' : langcode.toLowerCase() : INITIAL_STATE.language)
	.set('dateFormat', dateFormat ? mapDBDateFormats(dateFormat) : INITIAL_STATE.dateFormat)
	.set('timeFormat', timeFormat ? mapDBTimeFormats(timeFormat) : INITIAL_STATE.timeFormat)
	.set('decimalSeparator', decimalChar ? decimalChar : INITIAL_STATE.decimalSeparator)
	.set('thousandSeparator', thousandsChar ? thousandsChar : INITIAL_STATE.thousandSeparator)
}

function userLoginFailed(state, action) {
	const { message } = action.payload
	return state
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('token', null)
	.set('loading', false)
	.set('isLoggedIn', false)
}

function requestResetPassword(state) {
	return state
	.set('message', null)
	.set('loading', true)
	.set('passwordReset', false)
}

function requestResetPasswordSucceeded(state, action) {
	const { recoveryPwdTime, resetTime } = action.payload
	return state
	.set('message', fromJS({
		text: 'request_new_pass_success', variant: 'success'
	}))
	.set('loading', false)
	.set('recoveryPwdTime', recoveryPwdTime)
	.set('resetTime', resetTime)
	.set('passwordReset', true)
}

function requestResetPasswordFailed(state, action) {
	const { message } = action.payload
	return state
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('token', null)
	.set('loading', false)
	.set('isLoggedIn', false)
	.set('passwordReset', false)
}

function resetPassword(state) {
	return state
	.set('message', null)
	.set('loading', true)
	.set('successResetPass', false)
}

function resetPasswordSucceeded(state, action) {
	return state
	.set('message', fromJS({
		text: 'reset_success', variant: 'success'
	}))
	.set('loading', false)
	.set('recoveryPwdTime', null)
	.set('resetTime', null)
	.set('passwordReset', false)
	.set('recoveryPwdTime', null)
	.set('resetTime', null)
	.set('successResetPass', true)
}

function resetPasswordFailed(state, action) {
	const { message } = action.payload
	return state
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('token', null)
	.set('loading', false)
	.set('isLoggedIn', false)
	.set('successResetPass', false)
}

function getUserDetails(state) {
	return state
	.set('loading', true)
	.set('message', null)
}

function getUserDetailsSucceeded(state, action) {
	const { user } = action.payload;
	const mappedUser = mapUser(user[0])
	return state
	.set('loading', false)
	.set('data', fromJS(mappedUser))
	.set('message', null)
}

function getUserDetailsFailed(state, action) {
	const { message } = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function loginToITYEApi(state) {
	return state
	.set('loadingITYE', true)
	.set('message', null)
}

function loginToITYEApiSucceeded(state, action) {
	const { token } = action.payload.data
	return state
	.set('loadingITYE', false)
	.set('apiITYEToken', token)
	.set('message', null)
}

function loginToITYEApiFailed(state, action) {
	const { message } = action.payload
	return state
	.set('loadingITYE', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

const currentUser = {
	initialState: INITIAL_STATE,
	handlers: {
		[ActionTypes.SET_USER_LANGUAGE]: setUserLanguage,
		[ActionTypes.SET_USER_MESSAGE]: setUserMessage,
		[ActionTypes.SET_RESET_PASSWORD]: setResetPassword,
		[ActionTypes.CHECK_API_CONNECTIVITY]: checkAPIConnectivity,
		[ActionTypes.CHECK_API_CONNECTIVITY_SUCCEEDED]: checkAPIConnectivitySucceeded,
		[ActionTypes.CHECK_API_CONNECTIVITY_FAILED]: checkAPIConnectivityFailed,
		[ActionTypes.USER_LOGOUT_SUCCEEDED]: userLogoutSucceeded,
		[ActionTypes.USER_LOGIN]: userLogin,
		[ActionTypes.USER_LOGIN_SUCCEEDED]: userLoginSucceeded,
		[ActionTypes.USER_LOGIN_FAILED]: userLoginFailed,
		[ActionTypes.REQUEST_RESET_PASSWORD]: requestResetPassword,
		[ActionTypes.REQUEST_RESET_PASSWORD_SUCCEEDED]: requestResetPasswordSucceeded,
		[ActionTypes.REQUEST_RESET_PASSWORD_FAILED]: requestResetPasswordFailed,
		[ActionTypes.RESET_PASSWORD]: resetPassword,
		[ActionTypes.RESET_PASSWORD_SUCCEEDED]: resetPasswordSucceeded,
		[ActionTypes.RESET_PASSWORD_FAILED]: resetPasswordFailed,
		[ActionTypes.GET_USER_DETAILS]: getUserDetails,
		[ActionTypes.GET_USER_DETAILS_SUCCEEDED]: getUserDetailsSucceeded,
		[ActionTypes.GET_USER_DETAILS_FAILED]: getUserDetailsFailed,
		[ActionTypes.LOGIN_TO_ITYE_API]: loginToITYEApi,
		[ActionTypes.LOGIN_TO_ITYE_API_SUCCEEDED]: loginToITYEApiSucceeded,
		[ActionTypes.LOGIN_TO_ITYE_API_FAILED]: loginToITYEApiFailed,
	}
}

export default currentUser