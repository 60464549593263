// @flow
import Grid from "@mui/material/Grid2";
import React from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../locales/pages/namespaces";
import Box from "@mui/material/Box";
import {Divider, Typography} from "@mui/material";
import useUserData from "../../hooks/user/useUserData";
import MainCard from "../../components/mui/general/mainCard/mainCard";

type Props = {};

const Profile = (props: Props) => {
	const {t} = useTranslation(PAGE_HOME)
	const {data} = useUserData()
	return (
		<Box>
			<Box p={1}>
				<Typography
					pb={1}
					variant={'h2'}
					component={'div'}
					sx={{
						textAlign: 'center'
					}}
				>
					{t('My Profile')}
				</Typography>
				<Box sx={{display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'center'}}>
					<Box width={'50%'} m={1}>
						<MainCard sx={{
							mb: 2,
							border: 'none',
							height: '100%',
							textAlign: 'center'
						}}>
							<Grid container>
								<Grid size={{xs: 12, sm: 6}}>
									<strong>{t('Username')}</strong>
								</Grid>
								<Grid  size={{xs: 12, sm: 6}}>
									{data?.username}
								</Grid>
							</Grid>
							<Divider/>
							<Box sx={{textAlign: 'center'}} p={1}>
								<Typography
									variant={'h4'}
									component={'div'}
								>
									{t('More Info')}
								</Typography>
								
							</Box>
						</MainCard>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Profile