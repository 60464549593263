// ==============================|| OVERRIDES - ACCORDION DETAILS ||============================== //

export default function AccordionDetails(theme) {
	return {
		MuiAccordionDetails: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
