//@flow
import React from 'react';
import ContentRouter from "../../routers/contentRouter";
import Box from "@mui/material/Box";
import Header from "../../components/app/layout/header/header";
import Footer from "../../components/app/layout/footer/footer";

export const TitleContext = React.createContext(null)

const Content = () => {
	const [title, setTitle] = React.useState(null)
	return (
		<TitleContext.Provider
			value={{
				title: title,
				setTitle: setTitle
			}}
		>
			<Box
				component="main"
				className={'content-wrap'}
			>
				<Header/>
				<ContentRouter/>
				<Footer/>
			</Box>
		</TitleContext.Provider>
	)
}

export default Content;
