// ==============================|| OVERRIDES - LIST ||============================== //

export default function List(theme) {
	return {
		MuiList: {
			styleOverrides: {
				root: {}
			}
		}
	}
}