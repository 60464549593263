// ==============================|| OVERRIDES - TAB ||============================== //

export default function Tab(theme) {
	return {
		MuiTab: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {},
				}
			}
		}
	}
}
