// ==============================|| OVERRIDES - TEXT FIELD ||============================== //

export default function TextField(theme) {
	return {
		MuiTextField: {
			styleOverrides: {
				root: {
					input: {}
				}
			}
		}
	}
}