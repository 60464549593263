// ==============================|| OVERRIDES - DIALOG ||============================== //

export default function Dialog(theme) {
	return {
		MuiDialog: {
			styleOverrides: {
				root: {}
			}
		}
	}
}
