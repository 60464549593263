
// ==============================|| OVERRIDES - MENU ITEM ||============================== //

export default function MenuItem(theme) {
	return {
		MuiMenuItem: {
			styleOverrides: {
				root: {
					// whiteSpace: 'nowrap'
				}
			}
		}
	}
}
