// @flow
import * as moment from "moment/moment";
import React from "react";
import {
	Box, Divider, Table, TableBody, TableCell, TableRow, Typography
} from "@mui/material"
import { useTranslation } from "react-i18next";
import QuickViewInfoWrap from "../../../../components/mui/app/quickViewInfoWrap/quickViewInfoWrap";
import ModalHeader from "../../../../components/mui/general/modal/partials/modalHeader";
import { CONTRACTORS } from "../../locales/namespaces";

const Details = (props) => {
	const data = props.route;
	const rid = props.rid;
	console.log(data);
	const onClose = props.onClose;
	const { t } = useTranslation(CONTRACTORS);
	const filteredData = data?.filter(data => data.rid === rid)[0];
	
	console.log(filteredData);
	return (
		<Box>
			<ModalHeader
				title={t('Route Details')}
				withDivider
				onClose={onClose}
			/>
			<Box sx={{ backgroundColor: 'aliceblue' }}>
				{!filteredData ?
					<Typography variant="h4" component={'div'} sx={{ textAlign: 'center' }}>
						{t('There are not data!')}
					</Typography>
					:
					
					<>
						<Typography variant="h4" component={'div'} sx={{ textAlign: 'center' }}>
							{filteredData?.title}
						</Typography>
						<Typography component={'div'} sx={{ textAlign: 'center', fontSize: '14px' }}>
							{filteredData?.descr}
						</Typography>
					</>
				}
				<Divider/>
			</Box>
			<QuickViewInfoWrap
				label={t('Back Route')}> {filteredData?.dromologio_epistrofhs ? filteredData.dromologio_epistrofhs : '-'}</QuickViewInfoWrap>
			<QuickViewInfoWrap
				label={t('Αssignment Price')}> {filteredData?.timh_anatheshs ? filteredData.timh_anatheshs : '-'}</QuickViewInfoWrap>
			<QuickViewInfoWrap
				label={t('Math Price')}> {filteredData?.timh_mathimatikou_typou ? filteredData.timh_mathimatikou_typou : '-'}</QuickViewInfoWrap>
			{filteredData?.dates.length > 0 ?
				<Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#f5f5dc8c' }}>
					<Table sx={{ marginTop: '20px', width: '50%' }}>
						<TableBody>
							<TableRow>
								<TableCell
									sx={{ fontWeight: 'bold', padding: '0px 0px 0px 10px' }}>{t('Dates')}</TableCell>
								<TableCell
									sx={{ fontWeight: 'bold', padding: '0px 0px 0px 10px' }}>{t('Price')}</TableCell>
							</TableRow>
							{filteredData?.dates.map(date => (
									<TableRow>
										<TableCell
											sx={{ padding: '0px 0px 0px 10px' }}>{moment(date).format("DD/MM/YYYY")}</TableCell>
										<TableCell sx={{
											padding: '0px 0px 0px 10px',
											textAlign: 'right'
										}}>{filteredData?.timh_anatheshs} </TableCell>
									</TableRow>
								)
							)}
							<TableRow sx={{ backgroundColor: '#0069ff59 !important' }}>
								<TableCell sx={{ padding: '0px 0px 0px 10px' }}>{t('Total')}</TableCell>
								<TableCell sx={{
									padding: '0px 0px 0px 10px',
									textAlign: 'right'
								}}>{filteredData?.dates.length * filteredData?.timh_anatheshs} </TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Box>
				:
				<Box sx={{ backgroundColor: '#f5f5dc8c', marginTop: '20px' }}>
					<Typography variant="h6" component={'div'} sx={{ textAlign: 'center' }}>
						{t('There are not dates!')}
					</Typography>
				</Box>
			}
		</Box>
	)
}

// export default withPermissionHOC(Details, ['access_user_details']) //TODO::Uncomment when permissions are ready
export default Details //TODO::Delete when permissions are ready