// ==============================|| OVERRIDES - AUTOCOMPLETE ||============================== //

export default function Autocomplete(theme) {
	return {
		MuiAutocomplete: {
			styleOverrides: {
				// popupIndicator: {
				// 	width: 'auto',
				// 	height: 'auto'
				// },
				// clearIndicator: {
				// 	width: 'auto',
				// 	height: 'auto'
				// },
				// root: {
				// 	'& .MuiInput-root .MuiInput-input': {
				// 		padding: 0
				// 	},
				// 	padding: 0,
				// 	'& .Mui-expanded': {
				// 		padding: 0
				// 	}
				// },
				// option: {
				// 	fontSize: '0.85rem',
				// 	fontWeight: 400,
				// 	padding: '4px 8px',
				// },
			}
		}
	}
}

