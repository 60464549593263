// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from "axios";
import Modal from "../../../../components/mui/general/modal/modal";

import useUserData from "../../../../hooks/user/useUserData";
import {
	Box,
	Card,
	CardActions,
	CardContent,
	Checkbox,
	FormControlLabel,
	ListItemButton,
	ListItemText
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import './parentDashboard.css';
import { useTranslation } from "react-i18next";
import useParent from "../../hooks/useParent";
import { PARENTS } from "../../locales/namespaces";
import SkeletonLoaderWrapper from "../../../../components/mui/general/skeletonLoaderWrapper/skeletonLoaderWrapper";
import MainCard from "../../../../components/mui/general/mainCard/mainCard";
import QuickViewInfoWrap from "../../../../components/mui/app/quickViewInfoWrap/quickViewInfoWrap";
import Grid from "@mui/material/Grid2";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { RoutesMap } from "../../components/routesMap/routesMap";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import { Edit } from "@mui/icons-material";
import QuickView from "../../../../components/mui/general/quickView/quickView";

type Props = {};

const apiITYEToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImVyZ29sb2dpYyIsImlhdCI6MTcyNTUyODk0NCwiZXhwIjoxNzQxMDgwOTQ0fQ.43d8MR7i6yxvJcR4CNTagZtHg1lamF1d8v9vGrM32BA';

const ParentDashboard = (props: Props) => {
	
	const { t } = useTranslation(PARENTS);
	const [item, setItem] = useState({});
	const [showMap, setShowMap] = useState(false);
	const [studentInfo, setStudentInfo] = useState({});
	const [studentName, setStudentName] = useState(null);
	const [checkedItems, setCheckedItems] = useState({});
	const [routeType, setRouteType] = useState();
	const [draggableValue, setDraggableValue] = useState(false);
	const { parentData,afm, loading } = useParent()
	
	const showStudentRoute = (child, item) => {
		setItem(item);
		setStudentName(child.stud_fname + child.stud_lname);
		setDraggableValue(false);
		const isChecked = checkedItems[`${child?.stud_fname + child?.stud_lname}`];
		if (isChecked) {
			setStudentInfo(child)
		} else {
			setStudentInfo(null)
		}
		setShowMap(true);
	}
	
	const handleCheckboxChange = (event, child) => {
		const { name, checked } = event.target;
		setCheckedItems((prevState) => ({
			...prevState,
			[name]: checked, // Update the state for the changed checkbox
		}));
		setStudentName(child.stud_fname + child.stud_lname);
		if (checked) {
			console.log(`${child?.stud_fname + child?.stud_lname} is checked!`);
			setStudentInfo(child)
		} else {
			console.log(`${child?.stud_fname + child?.stud_lname} is unchecked!`);
			setStudentInfo(null)
		}
		
	};
	
	const handleToSchoolEdit = (event, child, item) => {
		console.log(child, item)
		event.stopPropagation(); //gia na min patietai to list item me to click tou iconButton.
		setStudentInfo(child)
		setStudentName(child.stud_fname + child.stud_lname);
		setItem(item);
		setRouteType('toSchool');
		setDraggableValue(true);
		setShowMap(true);
	}
	
	const handleFromSchoolEdit = (event, child, item) => {
		console.log(child, item)
		event.stopPropagation(); //gia na min patietai to list item me to click tou iconButton.
		setStudentInfo(child)
		setStudentName(child.stud_fname + child.stud_lname);
		setItem(item);
		setRouteType('fromSchool');
		setDraggableValue(true);
		setShowMap(true);
	}
	
	const closeQuickView = () => {
		setShowMap(false);
		setDraggableValue(false);
	}
	
	return (
		<Grid sx={{ minWidth: '100%' }}>
			<Box sx={{ margin: '20px' }}>
				<Typography><b>{t('Guardian Name')}:</b> {afm}</Typography>
			</Box>
			<SkeletonLoaderWrapper loading={loading}>
				
				<Box className="cardContents">
					{parentData.map(child => (
						<MainCard
							title={
								<Typography variant="h4" component={'div'} sx={{ textAlign: 'center' }}>
									{child?.stud_fname}{' '}{child?.stud_lname}
								</Typography>
							}
							sx={{ flex: 'auto', border: '0px', marginTop: '0px' }}
						>
							<Grid container display={'contents'} wrap={'wrap'} alignItems={'center'}>
								<Grid textAlign={'end'}>
									<FormControlLabel
										control={
											<Checkbox
												size="small"
												name={child?.stud_fname + child?.stud_lname}
												onChange={(event) => handleCheckboxChange(event, child)}
											/>
										}
										label={t('Appear to map')}/>
								</Grid>
								<Grid>
									<QuickViewInfoWrap
										label={t('Address')}> {child.stud_address ? child.stud_address : '-'} </QuickViewInfoWrap>
								</Grid>
							
							</Grid>
							<QuickViewInfoWrap
								label={t('School')}> {child.sch_name ? child.sch_name : '-'}</QuickViewInfoWrap>
							<QuickViewInfoWrap
								label={t('Sch.Year')}> {child.schyear ? child.schyear : '-'} </QuickViewInfoWrap>
							<QuickViewInfoWrap
								label={t('Status')}> {child.appl_status ? child.appl_status : '-'} </QuickViewInfoWrap>
							<Divider/>
							<Grid>
								<Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
									{t('Itineraries')}
								</Typography>
							</Grid>
							{child?.routeToSch.map((item, index) => (
									<List>
										<ListItem key={index} disablePadding>
											<ListItemButton
												sx={{ padding: '0px' }}
												onClick={() => showStudentRoute(child, item)}
											>
												<ListItemText
													disableTypography
													primary={
														<Grid container
															  sx={{ display: 'flex', justifyContent: 'space-between' }}>
															<Grid size={{ xs: 10, md: 10 }}>
																<Typography>{item?.route_title} </Typography>
															</Grid>
															<Grid size={{ xs: 2, md: 2 }}>
																<IconButton
																	onClick={(event) => handleToSchoolEdit(event, child, item)}>
																	<Edit/>
																</IconButton>
															</Grid>
														</Grid>
													}
													secondary={
														<>
															<Typography variant={'body2'}
																		component={'div'}>{t('Itinerary to school')}</Typography>
															<QuickViewInfoWrap
																label={t('Sponsor')}> {item?.anadoxos ? item?.anadoxos : '-'}</QuickViewInfoWrap>
															<QuickViewInfoWrap
																label={t('Transfer Type')}> {item?.typos_metaforas ? item?.typos_metaforas : '-'}</QuickViewInfoWrap>
															<QuickViewInfoWrap
																label={t('Starting Point')}> {item?.busstop_in ? item?.busstop_in : '-'}</QuickViewInfoWrap>
															<QuickViewInfoWrap
																label={t('End Point')}> {item?.busstop_out ? item?.busstop_out : '-'}</QuickViewInfoWrap>
														</>
													}
												/>
											</ListItemButton>
										</ListItem>
									</List>
								)
							)}
							{child?.routeFromSch.map(item => (
									<List>
										<ListItem disablePadding>
											<ListItemButton
												sx={{ padding: '0px' }}
												onClick={() => showStudentRoute(child, item)}
											>
												<ListItemText
													disableTypography
													primary={
														<Grid
															container
															sx={{ display: 'flex', justifyContent: 'space-between' }}
														>
															<Grid size={{ xs: 10, md: 10 }}>
																<Typography>{item?.route_title} </Typography>
															</Grid>
															<Grid size={{ xs: 2, md: 2 }}>
																<IconButton
																	onClick={(event) => handleFromSchoolEdit(event, child, item)}>
																	<Edit/>
																</IconButton>
															</Grid>
														</Grid>
													}
													
													secondary={
														<>
															<Typography
																variant={'body2'}
																component={'span'}
															>
																{t('Itinerary from school')}
															</Typography>
															<QuickViewInfoWrap
																label={t('Sponsor')}> {item?.anadoxos ? item?.anadoxos : '-'}</QuickViewInfoWrap>
															<QuickViewInfoWrap
																label={t('Transfer Type')}> {item?.typos_metaforas ? item?.typos_metaforas : '-'}</QuickViewInfoWrap>
															<QuickViewInfoWrap
																label={t('Starting Point')}> {item?.busstop_in ? item?.busstop_in : '-'}</QuickViewInfoWrap>
															<QuickViewInfoWrap
																label={t('End Point')}> {item?.busstop_out ? item?.busstop_out : '-'}</QuickViewInfoWrap>
														</>
													}
												/>
											</ListItemButton>
										</ListItem>
									</List>
								)
							)}
						</MainCard>
					))}
				</Box>
				<Modal
					show={showMap}
					onBackgroundClick={closeQuickView}
				>
					<RoutesMap
						studentName={studentName}
						studentInfo={studentInfo}
						item={item}
						afm={afm}
						routeType={routeType}
						draggableValue={draggableValue}
						onClose={closeQuickView}
					/>
				</Modal>
			</SkeletonLoaderWrapper>
		</Grid>
	);
};

export default ParentDashboard